<template>
	<div class="sign-in">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle">

			<!-- Reset PW Form Column -->
			<a-col :span="24" :md="12" :lg="{span: 12, offset: 0}" :xl="{span: 6, offset: 2}" class="col-form">
				<h3 class="mb-15">Reset Password</h3>
				<a-alert v-if="resetSuccess" message="Please check your email for a link to reset your password." type="success" show-icon />
				<a-alert v-if="serverFailure" message="A server error prevented us from resetting your password. Please try again later." type="error" show-icon />

				<a-form
					id="components-form-demo-normal-login"
					:form="form"
					class="login-form"
					@submit="handleReset"
					:hideRequiredMark="true"
				>
					<a-form-item class="mb-10" label="Email" :colon="false">
						<a-input
						v-decorator="[
						'email',
						{ initialValue: emailReminder, rules: [{ required: true, message: 'Please input your email!' }] },
						]" placeholder="" />
					</a-form-item>
					
					<a-form-item>
						<a-button type="primary" block html-type="submit" class="login-form-button">
							RESET
						</a-button>
					</a-form-item>
				</a-form>
				
			</a-col>

			<a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img">
				<img src="images/largerLogo.jpeg" alt="">
				<h5>Serving businesses nationwide since 2005</h5>
			</a-col>

		</a-row>
		<br /><br /><br /><br /><br />
	</div>
</template>

<script>
import User from '../models/user';

export default ({
	computed: {
		loggedIn() {
			return this.$store.state.auth.status.loggedIn;
		},
	},
	created() {
		if (this.loggedIn) {
			this.$router.push("/dashboard");
		}
	},
	data() {
		return {
			resetSuccess: false,
			serverFailure: false,
			user: new User('', ''),
			emailReminder: localStorage.getItem('email'),
		}
	},
	beforeCreate() {
		// Creates the form and adds to it component's "form" property.
		this.form = this.$form.createForm(this, { name: 'normal_login' });
	},
	methods: {
		handleReset(e) {
			e.preventDefault();
			
			this.resetSuccess = false;
			this.serverFailure = false;
			
			this.form.validateFields((err, values) => {
				this.user.email = values.email;

				if ( !err ) {
					this.$store.dispatch("auth/reset", this.user).then(
						() => {
							this.resetSuccess = true;
						},
						(error) => {
							console.log(error);
							if(error.response.status == 500){
								this.serverFailure = true;
							}
							
							this.loading = false;
							this.message =
								(error.response &&
								error.response.data &&
								error.response.data.message) ||
								error.message ||
								error.toString();
						}
					);

				}
			});
		},
	},
})
</script>

<style lang="scss">
	body {
		background-color: #ffffff;
	}
</style>